<template>
<!-- 推荐电源点选择原因 -->
<div>
    <el-form :model="formData" :rules="formRules" ref="form" size="mini" style="margin: 20px;">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form-item label="选择原因" prop="recommendReason">
                    <el-checkbox-group v-model="formData.recommendReason">
                    <el-checkbox
                        v-for="(item, index) in keyValues.ReasonSelection"
                        :label="item.itemValue"
                        :key="index"
                    >{{item.itemValue}}</el-checkbox>
                        <el-checkbox @change="checkedOtherChange" label="其他">其他</el-checkbox>
                </el-checkbox-group>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="formData.checkedOther">
                <el-form-item label="其它原因" prop="recommendOtherReason">
                    <el-input v-model="formData.recommendOtherReason"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form-item label="拟接入系统简图" prop="accessSystemDiagramFile">
                    <ex-uploader v-model="formData.accessSystemDiagramFile" :singleFileMode='true'></ex-uploader>
                    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="相关说明：" class="li-txt-context">
                    <li>产权分界点设置：（详见供电方案）</li>
                        <el-form-item label="是否需要实施业扩工程配套项目：" prop="isAssortProject">
                            <el-radio v-model="formData.isAssortProject" v-for="item in keyValues.highReliabilitySupply" :label="item.itemKey" :key="item.itemKey">{{ item.itemValue }}</el-radio>
                        </el-form-item>
                    <li>涉及公用电房建设的业扩配套项目，客户需无偿提供公用电房位置及红线范围线路走廊路径图</li>
                    <li>其它<div>
                            <el-input v-model="formData.otherNote"></el-input>
                        </div>
                    </li>
                    <!--<div style="margin-top: 20px;"><span style="color: #F56C6C;margin-right: 5px;">*</span>客户签名：<el-button @click="openSignDlg(1)" style="display: inline-block;">点击进入电子签章</el-button></div>
                    <img :src="signImg1" style="width: 300px;height: auto" />-->

                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <sign ref="ref_elec_sign" class="sign_powerpoint" v-if="showSign" @success="signCallback"></sign>
</div>
</template>

<script>
import Sign from '@/components/Sign/index';
import axios from '@/client/axios';
export default {
    name: 'PowerPointSelectionReason',
    components: {
        Sign
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    recommendReason: [],
                    accessSystemDiagram: '',
                    accessSystemDiagramFile: [],
                    isAssortProject: '',
                    otherNote: '',
                    reconnaissanceCustomerSignature: {},
                    recommendOtherReason: '',
                    checkedOther: false
                };
            }
        }
    },
    computed: {
        signImg1() {
            if (this.formData.reconnaissanceCustomerSignature && this.formData.reconnaissanceCustomerSignature.file) {
                return `${this.$client.serverUrl}files/${this.formData.reconnaissanceCustomerSignature.file.id}?token=${this.$store.state.token}`;
            }
            return '';
        },
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            openPowersPlan: false,
            showSign: false,
            loading: false,
            bSignature: [],
            formRules: {
                isAssortProject: [{
                    required: true,
                    message: '工程配套项目' + this.$t('common.required'),
                    trigger: 'change',
                }],
                /*accessSystemDiagramFile: [{
                    required: true,
                    message: '拟接入系统简图' + this.$t('common.required'),
                    trigger: 'change',
                }],*/
                recommendReason: [{
                    required: true,
                    message: '选择原因' + this.$t('common.required'),
                    trigger: 'change',
                }],
                recommendOtherReason: [{
                    required: true,
                    message: '其他原因' + this.$t('common.required'),
                    trigger: 'change',
                }]
            },
            keyValues: {
                selectLineList: [],
                ReasonSelection: [],
                highReliabilitySupply: [
                    {
                        itemKey: 1,
                        itemValue: '是'
                    },
                    {
                        itemKey: 2,
                        itemValue: '否'
                    }
                ],
            },
            uploadHeaders: {
                token: this.$store.state.token
            },
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getDicData('power_point_selection_reason'),
            ]);
            this.keyValues.ReasonSelection = data[0].data || [];
        },
        // TODO 删除一行记录，还差一个索引
        removeEquipment() {

        },
        openSignDlg(type) {
            this.showSign = true;
            this.$nextTick(() => {
                this.$refs.ref_elec_sign.init(type);
            });
        },
        signCallback(data, type) {
            if (type === 1) {
                this.formData.reconnaissanceCustomerSignature = {};
                this.formData.reconnaissanceCustomerSignature.file = data;
                this.formData.reconnaissanceCustomerSignature.signatureName = '客户签名';
            }
            this.clearSignDom('.sign_powerpoint');
        },
        clearSignDom(className) {
            try {
                document.querySelector(className).remove();
            } catch (e) {
                console.log('clearSignDomo run error');
            }
        },
        checkboxChange(checked, index) {
            if (index === '1') {
                this.openPowersPlan = checked;
            }
        },
        checkedOtherChange(checked) {
            if (!checked) {
                this.formData.recommendOtherReason = '';
                this.formData.checkedOther = false;
            } else {this.formData.checkedOther = true;}
        }
    },
    created() {
        this.bindKeyValues();
    },
};
</script>

<style lang="less" scoped>
.selectheader_close {
    cursor: pointer;
    float: right;
    position: relative;
    z-index: 3;
    top: 16px;
}

.li-txt-context {
    .el-form-item__label {
        width: 100%;
        text-align: left;
    }
}

/deep/ .el-dialog--center {
    margin-top: 31vh;
    margin-left: 30vh;
}

/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>
