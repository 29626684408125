<template>
<el-form :model="formData" :rules="formRules" ref="form" size="mini">
    <el-row :gutter="24" class="line-way-sline">
        <el-col :span="24">
            <el-form-item label="变压器容量" prop="transformerCapacity">
                <el-input-number v-model="formData.transformerCapacity"></el-input-number>
            </el-form-item>
            <el-form-item label="变压器数量" prop="num">
                <el-input-number v-model="formData.num" :min="1"></el-input-number>
            </el-form-item>
            <i class="el-icon-close qu-row_close" @click="removeEquipment"></i>
        </el-col>
    </el-row>
</el-form>
</template>

<script>
export default {
    name: 'EquipmentInfo',
    components: {},
    props: {
        callback: {
            type: Function,
            default: () => {}
        },
        index: {default: 0},
        formData: {
            type: Object,
            default: () => {
                return {
                    transformerCapacity: 0,
                    num: 1,
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                transformerCapacity: [{
                    required: true,
                    message: '变压器容量' + this.$t('common.required'),
                    trigger: 'change',
                }],
                num: [{
                    required: true,
                    message: '变压器数量' + this.$t('common.required'),
                    trigger: 'change',
                }]
            }
        };
    },
    methods: {
        // 删除一行记录
        removeEquipment() {
            const index = this.index;
            if (typeof (this.callback) === 'function') {
                this.callback(index);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.line-way-sline {
    border-bottom: 1px #84bef3 dotted;
}
/deep/ .el-form-item--mini {
    display: inline-block;
    float: left;
    margin-right: 10%;
}
.qu-row_close {
    position: absolute;
    top: 50%;
    cursor: pointer;
}
</style>
