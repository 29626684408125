<template>
<!-- 接入方式 -->
<el-form :model="formData" :rules="formRules" ref="form" size="mini">
    <div class="ulbox">
        <div v-for="(item, index) in formData.transformerCapacitys" :key="index">
            <equipment ref="equipmentGroup" :formData="item" :index="index" :callback="removeEquipment"></equipment>
        </div>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-button @click="addEquipment" icon="el-icon-plus" style="margin: 20px 0 10px;">新增变压器</el-button>
            </el-col>
            <el-col :span="12"></el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form-item label="接入属性" prop="accessMethod">
                    <el-checkbox v-model="formData.singerPowerWay" :false-label="0" :true-label="1">单电源接入</el-checkbox>
                    <el-checkbox v-model="formData.highPowerWay" :false-label="0" :true-label="1">高可靠性供电（多电源）</el-checkbox>
                    <el-checkbox v-model="formData.communityWay" :false-label="0" :true-label="1">住宅小区配电站环网接入</el-checkbox>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="formData.highPowerWay===1">
                <el-form-item label="接入回数" prop="highPowerNum">
                    <el-input-number :min="2" v-model="formData.highPowerNum"></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>

    </div>
</el-form>
</template>

<script>
import {
    ACCESS_TYPE
} from '@/utils/constants';
import Equipment from './equipment';
export default {
    name: 'CustomerInfo',
    components: {
        Equipment
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    highPowerNum: 2,
                    transformerCapacitys: [],
                    singerPowerWay: '',
                    highPowerWay: '',
                    communityWay: ''
                };
            },
        },
    },
    data() {
        return {
            loading: false,
            openAccessTimes: false,
            formRules: {
                // customerName: [{
                //     required: true,
                //     message: '客户名称' + ' ' + '必填',
                //     trigger: 'change',
                // }],
            },
            keyValues: {
                customerAccessType: ACCESS_TYPE
            },
            equipmentInfo: {
                transformerCapacity: 0,
                num: '',
            },
        };
    },
    computed: {
    },
    methods: {
        // 新增变压器
        addEquipment() {
            this.formData.transformerCapacitys.push({
                ...this.equipmentInfo
            });
        },
        // 删除变压器
        removeEquipment(index) {
            this.formData.transformerCapacitys.splice(index, 1);
        },
        accessMethodChange(checked, index) {
            if (index === 10) {
                this.formData.singerPowerWay = checked ? 1 : 0;
                this.openAccessTimes = checked;
            } else {
                this.formData.singerPowerWay = checked ? 1 : 0;
            }
        }
    },
    created() {
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}
</style>
