<template>
<!-- 接入方式 -->
<el-form :model="formData" :rules="formRules" ref="form" size="mini">
    <div class="ulbox">
        <el-row :gutter="24" class="form-li">
            <el-col :span="24">
                <el-form-item label="客户名称:">
                    {{formData.customerName}}
                </el-form-item>
                <el-form-item label="工作单号:">
                    {{formData.businessNumber}}
                </el-form-item>
                <el-form-item label="用电地址:">
                    {{formData.useAddr}}
                </el-form-item>
                <el-form-item label="业务类别:">
                    {{formData.businessType}}
                </el-form-item>
                <el-form-item label="用电性质:">
                    {{formData.elecNature}}
                </el-form-item>
                <!-- <el-form-item label="行业用电分类:">
                    {{formData.industrys}}
                </el-form-item> -->
                <el-form-item label="原有容量:">
                    {{formData.oldCapacity}}
                </el-form-item>
                <el-form-item label="增/减容量:">
                    {{formData.updateCapacity}}
                </el-form-item>
                <el-form-item label="总容量:">
                    {{formData.totalCapacity}}
                </el-form-item>
                <el-form-item label="用电类别:">
                    {{formData.elecType}}
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24" v-if="subChooseBar">
            <el-col :span="8">
                <el-form-item label="高可靠性供电" prop="highPower">
                    <el-radio v-model="formData.highPower" v-for="item in keyValues.highReliabilitySupply" :label="item.itemKey" :key="item.itemKey">{{ item.itemValue }}</el-radio>
                </el-form-item>
            </el-col>
            <el-col :span="8" v-if="parseInt(formData.highPower)===1">
                <el-form-item label="申请高可靠容量 (KVA)" prop="highPowerCapacity">
                    <el-input v-model="formData.highPowerCapacity"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="是否需要配备应急电源" prop="emergencyPower">
                    <el-radio v-model="formData.emergencyPower" v-for="item in keyValues.highReliabilitySupply" :label="item.itemKey" :key="item.itemKey">{{ item.itemValue }}</el-radio>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</el-form>
</template>

<script>
export default {
    name: 'BusinessInformation',
    components: {},
    props: {
        subChooseBar: {
            default: true
        },
        id: null,
        formData: {
            type: Object,
            default: () => {
                return {
                    customerName: '',
                    businessNumber: '',
                    useAddr: '',
                    businessType: '',
                    elecNature: '',
                    industrys: '',
                    oldCapacity: '',
                    updateCapacity: '',
                    totalCapacity: '',
                    elecType: '',
                    highPower: 1,
                    highPowerCapacity: '',
                    emergencyPower: 1
                };
            }
        }
    },
    data() {
        return {
            // elec_nature: [],
            // elec_type: [],
            loading: false,
            formRules: {
                highPowerCapacity: [{
                    required: true,
                    message: '申请高可靠容量' + ' ' + '必填',
                    trigger: 'change',
                }],
                highPower: [{
                    required: true,
                    message: '高可靠性供电' + ' ' + '必填',
                    trigger: 'change',
                }],
                emergencyPower: [{
                    required: true,
                    message: '是否需要配备应急电源' + ' ' + '必填',
                    trigger: 'change',
                }]
            },
            keyValues: {
                highReliabilitySupply: [
                    {
                        itemKey: 1,
                        itemValue: '是'
                    },
                    {
                        itemKey: 0,
                        itemValue: '否'
                    }

                ],
                customerIdentity: [],
                customerIndustry: [],
                customerType: [],
                elecAssureType: [],
            }
        };
    },
    computed: {},
    methods: {
        // async bindKeyValues() {
        //     const data = await Promise.all([
        //         this.$client.getDicData('customer_identity'),
        //         this.$client.getDicData('customer_industry'),
        //         this.$client.getDicData('customer_type'),
        //         this.$client.getDicData('elec_assure_type'),
        //     ]);
        //     this.keyValues.customerIdentity = data[0].data || [];
        //     this.keyValues.customerIndustry = data[1].data || [];
        //     this.keyValues.customerType = data[2].data || [];
        //     this.keyValues.elecAssureType = data[3].data || [];
        // },
        // findDicValue(key, data) {
        //     let result = '';
        //     if (key && data instanceof Array && data.length > 0) {
        //         const find = data.find((f) => {
        //             return f.itemKey === key.toString();
        //         });
        //         if (find) {
        //             result = find.itemValue;
        //         }
        //     }
        //     return result;
        // },
        // async initFormValue() {
        //     // const ret = await this.$client.getReconnaissance(this.id);
        //     // if (ret && ret.data) {
        //     //     this.formData = Object.assign({}, ret.data);
        //     // }
        //     // const elecNature = await this.$client.getDicData('elec_nature');
        //     // if (elecNature && elecNature.data) {
        //     //     this.elec_nature = elecNature.data;
        //     // }
        //     const ret = await Promise.all([
        //         this.$client.getDicData('elec_nature'),
        //         this.$client.getDicData('elec_type')
        //     ]);
        //     this.elec_nature = ret[0].data || [];
        //     this.elec_type = ret[1].data || [];
        // },
    },
    created() {
        // this.initFormValue();
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    float: left;
    text-align: left;
    width: 100%;
}

.form-li {
    /deep/ .el-form-item__label {
        float: left;
        text-align: right;
        width: 100px;
    }
}
</style>
