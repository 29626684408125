<template>
<!-- 勘察意见 -->
<div>
    <el-form :model="formData" :rules="formRules" ref="form" size="mini" style="margin: 20px;">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form-item label="" prop="reconnaissanceSCSignature.signatureAdvice">
                    <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="formData.reconnaissanceSCSignature.signatureAdvice">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <div><span style="color: #F56C6C;margin-right: 5px;">*</span>业扩勘察人签名(市场部)：<el-button @click="openSignDlg(2)" style="display: inline-block;" type="primary">{{$t('businessApply.form.clickToSignDlg')}}</el-button></div>
                <img :src="signImg2" style="width: 300px;height: auto;margin: 20px 0 20px;" />

            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form-item label="" prop="reconnaissanceFJSignature.signatureAdvice">
                    <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="formData.reconnaissanceFJSignature.signatureAdvice">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <div><span style="color: #F56C6C;margin-right: 5px;">*</span>业扩勘察人签名(发计部)：<el-button @click="openSignDlg(3)" style="display: inline-block;" type="primary">{{$t('businessApply.form.clickToSignDlg')}}</el-button></div>
                <img :src="signImg3" style="width: 300px;height: auto;margin: 20px 0 20px;" />

            </el-col>
        </el-row>

    </el-form>
    <sign ref="ref_elec_sign" class="sign_so" v-if="showSign" @success="signCallback"></sign>
</div>
</template>

<script>
import Sign from '@/components/Sign/index';
import axios from '@/client/axios';
export default {
    name: 'SurveyOpinion',
    components: {
        Sign
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    reconnaissanceSCSignature: {
                        file: null,
                        signatureAdvice: ''
                    },
                    reconnaissanceFJSignature: {
                        file: null,
                        signatureAdvice: ''
                    }
                };
            }
        }
    },
    computed: {
        signImg2() {
            if (this.formData.reconnaissanceSCSignature && this.formData.reconnaissanceSCSignature.file) {
                return `${this.$client.serverUrl}files/${this.formData.reconnaissanceSCSignature.file.id}?token=${this.$store.state.token}`;
            }
            return '';
        },
        signImg3() {
            if (this.formData.reconnaissanceFJSignature && this.formData.reconnaissanceFJSignature.file) {
                return `${this.$client.serverUrl}files/${this.formData.reconnaissanceFJSignature.file.id}?token=${this.$store.state.token}`;
            }
            return '';
        },
    },
    data() {
        return {
            formRules: {
                /*'reconnaissanceSCSignature.signatureAdvice': [{
                    required: true,
                    message: '勘查市场部签名 勘察意见' + ' ' + this.$t('common.required'),
                    trigger: 'change',
                }],
                'reconnaissanceFJSignature.signatureAdvice': [{
                    required: true,
                    message: '勘查发计部签名 勘察意见' + ' ' + this.$t('common.required'),
                    trigger: 'change',
                }],*/
            },
            dialogImageUrl: '',
            dialogVisible: false,
            showSign: false,
            loading: false,
            signIndex: '',
        };
    },
    methods: {
        initFormValue() {
        },
        openSignDlg(type) {
            this.showSign = true;
            this.signIndex = type;
            this.$nextTick(() => {
                this.$refs.ref_elec_sign.init(type);
            });
        },
        signCallback(data, type) {
            if (type === 2) {
                this.$set(this.formData.reconnaissanceSCSignature, 'file', data);
                this.formData.reconnaissanceSCSignature.signatureName = '勘查市场部签名';
            } else if (type === 3) {
                this.$set(this.formData.reconnaissanceFJSignature, 'file', data);
                this.formData.reconnaissanceFJSignature.signatureName = '勘查发计部签名';
            }
            this.clearSignDom('.sign_so');
        },
        clearSignDom(className) {
            try {
                document.querySelector(className).remove();
            } catch (e) {
                console.log('clearSignDomo run error');
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        }
    },
    created() {
        this.initFormValue();
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center {
    margin-top: 31vh;
    margin-left: 30vh;
}

/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}

/deep/ .el-textarea {
    margin-top: 20px;
}
</style>
