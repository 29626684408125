<template>
    <el-form :model="formData" :rules="formRules" ref="form" size="mini">
        <el-row :gutter="24">
            <el-col :span="24">
                <i class="el-icon-close selectheader_close" @click="removeEquipment"></i>
                <el-form-item label="选择线路" prop="line">
                    <!-- <el-select v-model="formData.selectLine">
                    <el-option v-for="item in keyValues.selectLineList" :key="item.itemKey" :value="item.itemKey" :label="item.itemValue"></el-option>
                    </el-select>-->
                    <el-cascader
                        v-model="line"
                        class="cascader-line"
                        :options="options"
                        :props="{expandTrigger: 'hover',label:'name',value:'id'}"
                        @change="handleChange"
                    ></el-cascader>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item label="线路属性" prop="lineType">
                    <!-- <el-radio v-model="formData.lineType" v-for="(item, index) in keyValues.lineType" :label="keyValues.lineType[item]" :key="index">{{ item }}</el-radio> -->
                    <el-radio-group v-model="formData.lineType">
                        <!-- <el-radio v-for="(item, index) in keyValues.lineType" :label="index" :key="index">{{item}}</el-radio> -->
                        <el-radio v-for="item in keyValues.lineType" :label="parseInt(item.itemKey)" :key="item.itemKey">
                            {{ item.itemValue }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="进线距离" prop="lineDistance">
                    <el-input v-model="formData.lineDistance"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="8">
                <el-form-item label="额定容量" prop="ratedCapacity">
                    <el-input-number :min="1" v-model="formData.ratedCapacity"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="在途容量" prop="usedCapacity">
                    <el-input-number v-model="formData.usedCapacity"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="可供容量" prop="surplusCapacity">
                    <el-input-number v-model="formData.surplusCapacity"></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
// import {LINE_TYPE} from '@/utils/constants';
export default {
    name: 'PowerLine',
    components: {},
    props: {
        callback: null,
        index: {
            default: 0
        },
        formData: {
            type: Object,
            default: () => {
                return {
                    apId: 0,
                    powerAp: '',
                    lineType: '',
                    lineDistance: '',
                    ratedCapacity: '',
                    usedCapacity: '',
                    surplusCapacity: ''
                };
            }
        }
    },
    data() {
        return {
            loading: false,
            line: [],
            formRules: {
                lineType: [
                    {
                        required: true,
                        message: '线路属性' + '必填',
                        trigger: 'change'
                    }
                ],
                lineDistance: [
                    {
                        required: true,
                        message: '进线距离' + '必填',
                        trigger: 'change'
                    }
                ],
                ratedCapacity: [
                    {
                        required: true,
                        message: '额定容量' + '必填',
                        trigger: 'change'
                    }
                ],
                usedCapacity: [
                    {
                        required: true,
                        message: '在途容量' + '必填',
                        trigger: 'change'
                    }
                ],
                surplusCapacity: [
                    {
                        required: true,
                        message: '可供容量' + '必填',
                        trigger: 'change'
                    }
                ]
            },
            keyValues: {
                // TODO 选择线路下拉
                selectLineList: [],
                lineType: []
            },
            options: []
        };
    },
    methods: {
        async initFormValue() {
            const ret = await Promise.all([
                this.$client.getDicData('line_type'),
                this.$client.getPowerGridsTrees(-1)
            ]);
            this.keyValues.lineType = ret[0].data || [];
            this.options = ret[1].data;
            if (this.formData.apId !== 0) {
                this.line = this.findPathFromTree(this.options, this.formData.apId).map(e => e.id);
            }
        },
        findPathFromTree(tree, id) {
            for (let i = 0; i < tree.length; i++) {
                let item = tree[i];
                if (item.id === id) {
                    return [item];
                }
                if (item.children) {
                    let result = this.findPathFromTree(item.children, id);
                    if (result.length > 0) {
                        result.splice(0, 0, item);
                        return result;
                    }
                }
            }
            return [];
        },
        // 删除一行记录
        removeEquipment() {
            const index = this.index;
            if (typeof this.callback === 'function') {
                this.callback(index);
            }
        },
        handleChange(e) {
            if (e.length > 0) {
                this.formData.apId = e[e.length - 1];
                this.formData.powerAp = this.findPathFromTree(this.options, this.formData.apId).map(e => e.name).join('');
            } else {
                this.formData.apId = 0;
                this.formData.powerAp = '';
            }
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>

<style lang="less" scoped>
.selectheader_close {
    cursor: pointer;
    float: right;
    position: relative;
    z-index: 3;
    top: 16px;
}

.el-cascader--mini {
    font-size: 12px;
    line-height: 28px;
    width: 100%;
}

.cascader-line {
    .el-scrollbar__wrap {
        overflow: hidden;
    }
}
</style>
