<template>
    <!-- 现场勘察情况 -->
    <el-form :model="formData" :rules="formRules" ref="form" size="mini">
        <div class="ulbox">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="推荐电源" prop="recommendPower">
                        <el-checkbox-group v-model="formData.recommendPower">
                        <el-checkbox
                            v-for="(item, index) in formData.powerPoints"
                            :label="item.id"
                            :key="index"
                        >{{ item.name }}</el-checkbox>
                        <el-checkbox  @change="checkedOtherChange">其他</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <div v-if="formData.checkOther">
                <div v-for="(item, index) in formData.otherReconnaissancePlans" :key="index" class="line-way-sline">
                    <power-line
                        ref="lineWayGroup"
                        :formData="item"
                        :index="index"
                        :callback="removeEquipment"
                    ></power-line>
                </div>
                <el-row :gutter="24">
                    <el-col :offset="10" :span="8">
                        <el-button @click="addEquipment" icon="el-icon-plus">新增电源</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-form>
</template>

<script>
import PowerLine from './powerLine';
export default {
    name: 'OnSiteInvestigation',
    components: {
        PowerLine
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    powerPoints: [],
                    recommendPower: '',
                    otherReconnaissancePlans: [],
                    checkOther: false
                };
            }
        },
        id: {
            type: [String, Number],
            default: '0'
        }
    },
    data() {
        return {
            loading: false,
            formRules: {
                recommendPower: [{
                    required: true,
                    message: '推荐电源' + ' ' + '必填',
                    trigger: 'change',
                }],
            },
            // 选择线路
            lineWay: {
                apId: 0,
                powerAp: '',
                lineType: '',
                lineDistance: '',
                ratedCapacity: '',
                usedCapacity: '',
                surplusCapacity: ''
            }
        };
    },
    computed: {},
    methods: {
        checkedOtherChange(checked) {
            if (!checked) {
                this.formData.checkOther = false;
                this.formData.otherReconnaissancePlans = [];
            } else {
                this.formData.checkOther = true;
                this.addEquipment();
            }
        },
        // 新增电源
        addEquipment() {
            this.formData.otherReconnaissancePlans.push({
                ...this.lineWay
            });
        },
        // 删除电源
        removeEquipment(index) {
            this.formData.otherReconnaissancePlans.splice(index, 1);
        },
        async initFormValue() {

        }
    },
    created() {
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 100%;
    float: left;
    text-align: left;
}

.line-way-sline {
    border-top: 1px #84bef3 dotted;
}
</style>
