<template>
    <!-- 勘测阶段 -->
    <div>
        <step-bar :active="1" @clickStep="clickStep"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>《电力客户供电方案勘察表》</span>
            </div>
            <div class="commonNotice">
                <div class="ul-center-box" style="font-size: 13px">
                    <!-- 业务信息 -->
                    <ex-card headerName="业务信息">
                        <div class="commonNotice">
                            <business-information
                                ref="form1"
                                :subChooseBar="true"
                                :id="id"
                                :formData="businessInformation"
                                class="commonNotice"
                            ></business-information>
                        </div>
                    </ex-card>

                    <!-- 接入方式 -->
                    <ex-card headerName="接入方式">
                        <div class="commonNotice">
                            <access-method ref="form2" :formData="accessMethod" class="commonNotice"></access-method>
                        </div>
                    </ex-card>

                    <!-- 初步接入方案 -->
                    <ex-card headerName="初步接入方案">
                        <div class="commonNotice">
                            <div v-for="(item, index) in accessPlanList" :key="index" style="margin: 10px 0 0 10px">
                                <span>{{ '电源点' + (index + 1) + ':' }}</span>
                                <div>{{ powerPointDescription(item) }}</div>
                                <div>{{ capacity(item) }}</div>
                            </div>
                        </div>
                    </ex-card>

                    <!-- 现场勘察情况 -->
                    <ex-card headerName="现场勘察情况">
                        <div class="commonNotice">
                            <on-site-investigation
                                ref="form3"
                                :id="id"
                                :formData="onSiteInvestigation"
                                class="commonNotice"
                            ></on-site-investigation>
                        </div>
                    </ex-card>

                    <!-- 推荐电源点选择原因 -->
                    <ex-card headerName="推荐电源点选择原因">
                        <div class="commonNotice">
                            <power-point-selection-reason
                                ref="form4"
                                :formData="powerPointSelectionReason"
                                class="commonNotice"
                            ></power-point-selection-reason>
                        </div>
                    </ex-card>

                    <!-- 勘察意见 -->
                    <ex-card headerName="勘察意见">
                        <div class="commonNotice">
                            <survey-opinion ref="form5" :formData="surveyOpinion" class="commonNotice"></survey-opinion>
                        </div>
                    </ex-card>
                </div>
            </div>
        </el-card>

        <el-row :gutter="24" class="submit-btn-group">
            <el-col :offset="8" :span="8">
                <el-button @click="cancel">取消</el-button>
                <el-button @click="save">保存</el-button>
                <el-button @click="submit">保存并发起</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import BusinessInformation from './businessInformation';
import AccessMethod from './accessMethod';
import OnSiteInvestigation from './onSiteInvestigation';
import PowerPointSelectionReason from './powerPointSelectionReason';
import SurveyOpinion from './surveyOpinion';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'ReconnaissanceForm',
    components: {
        BusinessInformation,
        AccessMethod,
        OnSiteInvestigation,
        PowerPointSelectionReason,
        SurveyOpinion,
        StepBar,
    },
    props: {
        id: {
            type: [String, Number],
            default: '0',
        },
        taskStatus: {
            default: 0,
        },
        workflowTaskId: {
            default: 0,
        },
    },
    computed: {
        total1() {
            let total = 0;
            for (const item of this.equipmentList) {
                total += item.capacityCount;
            }
            return `${total}(kW)`;
        },
        // todo 算法未知
        total2() {
            let total = 0;
            for (const item of this.equipmentList) {
                total += item.capacityCount;
            }
            return `${total}(kVA)`;
        },
    },
    data() {
        return {
            recId: 0,
            formState: 1,
            loading: false,
            businessInformation: {
                customerName: '',
                customerNumber: '',
                businessNumber: '',
                useAddr: '',
                businessType: '',
                elecNature: '',
                industrys: '',
                oldCapacity: '',
                updateCapacity: '',
                totalCapacity: '',
                elecType: '',
                highPower: 1,
                highPowerCapacity: '',
                emergencyPower: 1,
                tradeElecClass: '',
            },
            accessMethod: {
                highPowerNum: 2,
                transformerCapacitys: [],
                singerPowerWay: '',
                highPowerWay: '',
                communityWay: '',
            },
            onSiteInvestigation: {
                powerPoints: [],
                recommendPower: [],
                otherReconnaissancePlans: [],
                checkOther: false,
            },
            powerPointSelectionReason: {
                recommendReason: [],
                accessSystemDiagram: '',
                accessSystemDiagramFile: [],
                isAssortProject: '',
                otherNote: '',
                reconnaissanceCustomerSignature: null,
                recommendOtherReason: '',
                checkedOther: false,
            },
            surveyOpinion: {
                reconnaissanceSCSignature: null,
                reconnaissanceFJSignature: null,
            },
            initialAccessPlan: {},
            accessPlanList: [],
            // 折叠卡片包
            planFold: {
                businessInformation: false,
                accessMethod: false,
                onSiteInvestigation: false,
                powerPointSelectionReason: false,
                surveyOpinion: false,
                initialAccessPlan: false,
            },
        };
    },
    methods: {
        async initFormValue() {},
        powerPointDescription(item) {
            return `${item.powerAp} 进线距离：${item.lineDistance}, 负载余量：${item.surplusCapacity}`;
        },
        capacity(item) {
            return `线路属性：${item.lineType} 额定容量：${item.ratedCapacity} (KVA) 在途容量：${item.usedCapacity} (KVA)`;
        },
        // card 折叠方法
        shrinkComponents(name) {
            try {
                this.planFold[name] = !this.planFold[name];
            } catch (e) {
                console.log('shrinkComponents error');
            }
        },
        cancel() {
            this.closeElement();
        },
        save() {
            this.formState = 0;
            this.submit();
        },
        validate(form) {
            return new Promise((resolve) => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate((valid) => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        async submit() {
            let forms = [this.$refs.form1, this.$refs.form2, this.$refs.form3, this.$refs.form4, this.$refs.form5];

            for (let form of forms) {
                let valid = await this.validate(form.$refs.form);
                if (!valid) {
                    this.$message({
                        message: '请检查输入数据',
                        type: 'error',
                    });
                    return;
                }
            }

            if (!this.accessMethod.transformerCapacitys || this.accessMethod.transformerCapacitys.length === 0) {
                this.$message({
                    message: '请添加变压器',
                    type: 'error'
                });
                return;
            }
           /* if (!this.powerPointSelectionReason.reconnaissanceCustomerSignature) {
                this.$message({
                    message: '请检查客户电子签名',
                    type: 'error'
                });
                return;
            }*/
            if (!this.surveyOpinion.reconnaissanceSCSignature || !this.surveyOpinion.reconnaissanceSCSignature.file) {
                this.$message({
                    message: '请检查市场部电子签名',
                    type: 'error'
                });
                return;
            }
            if (!this.surveyOpinion.reconnaissanceFJSignature || !this.surveyOpinion.reconnaissanceFJSignature.file) {
                this.$message({
                    message: '请检查发计部电子签名',
                    type: 'error'
                });
                return;
            }

            let confirm = await this.$confirm('确定保存', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            });
            if (confirm !== 'confirm') {
                return;
            }
            this.loading = true;
            const sendForm = {
                id: this.recId,
                applyId: this.id,
                workflowTaskId: this.workflowTaskId,
                ...this.businessInformation,
                ...this.accessMethod,
                formState: this.formState,
                highPower: this.businessInformation.highPower,
                highPowerCapacity: this.businessInformation.highPowerCapacity,
                ...this.powerPointSelectionReason,
                recommendReason: this.powerPointSelectionReason.recommendReason.join(','),
                otherReconnaissancePlans: [...this.onSiteInvestigation.otherReconnaissancePlans],
                preReconnaissancePlans: [...this.accessPlanList],
                recommendPower: this.onSiteInvestigation.recommendPower.filter((e) => e !== 0).join(','),
                ...this.surveyOpinion,
                transformerCapacitys: JSON.stringify(this.accessMethod.transformerCapacitys),
            };
            this.$client.reconnaissanceAddOrUpdate(sendForm).then((data) => {
                this.$message({
                    message: '勘测结果保存成功!',
                    type: 'success',
                });
                this.loading = false;
                this.$emit('closePanel');
            });
        },
        // 新加电源点
        addAccessPlan() {
            debugger;
            this.accessPlanList.push({
                planName: '电源点',
                powerPointDescription: '',
                capacity: '',
            });
        },
        // 关闭窗体
        closeElement() {
            this.$confirm('确认取消？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$emit('closePanel');
            });
        },
        async initData() {
            await this.getReconnaissance();
        },
        async getReconnaissance() {
            await this.$client.getReconnaissanceInfo(this.id).then((result) => {
                if (result && result.code === 0) {
                    // 业务信息
                    this.recId = result.data.id;
                    this.businessInformation.customerName = result.data.customerName;
                    this.businessInformation.customerNumber = result.data.customerNumber;
                    this.businessInformation.businessNumber = result.data.businessNumber;
                    this.businessInformation.useAddr = result.data.useAddr;
                    this.businessInformation.businessType = result.data.businessType;
                    this.businessInformation.elecNature = result.data.elecNature;
                    this.businessInformation.oldCapacity = result.data.oldCapacity;
                    this.businessInformation.updateCapacity = result.data.updateCapacity;
                    this.businessInformation.totalCapacity = result.data.totalCapacity;
                    this.businessInformation.elecType = result.data.elecType;
                    this.businessInformation.highPower = result.data.highPower;
                    this.businessInformation.highPowerCapacity = result.data.highPowerCapacity;
                    this.businessInformation.emergencyPower = result.data.emergencyPower;
                    this.businessInformation.tradeElecClass = result.data.tradeElecClass;

                    // 接入方式
                    this.accessMethod.singerPowerWay = result.data.singerPowerWay;
                    this.accessMethod.highPowerWay = result.data.highPowerWay;
                    this.accessMethod.communityWay = result.data.communityWay;
                    this.accessMethod.highPowerNum = result.data.highPowerNum;
                    this.accessMethod.transformerCapacitys = JSON.parse(result.data.transformerCapacitys || '[]');

                    // 初步接入方案
                    this.accessPlanList = result.data.preReconnaissancePlans || [];

                    // 现场勘查情况
                    this.onSiteInvestigation.powerPoints = this.accessPlanList.map((item, index) => {
                        return {
                            id: item.apId,
                            name: '电源点' + (index + 1),
                        };
                    });
                    this.onSiteInvestigation.recommendPower = (result.data.recommendPower || '')
                        .split(',')
                        .filter((e) => e !== '')
                        .map((e) => parseInt(e));
                    this.onSiteInvestigation.otherReconnaissancePlans = result.data.otherReconnaissancePlans;
                    this.onSiteInvestigation.checkOther = result.data.otherReconnaissancePlans.length > 0;

                    // 推荐电源点选择原因
                    this.powerPointSelectionReason.recommendReason = (result.data.recommendReason || '')
                        .split(',')
                        .filter((e) => e !== '');
                    this.powerPointSelectionReason.accessSystemDiagram = result.data.accessSystemDiagram;
                    this.powerPointSelectionReason.accessSystemDiagramFile = result.data.accessSystemDiagramFile;
                    this.powerPointSelectionReason.isAssortProject = result.data.isAssortProject;
                    this.powerPointSelectionReason.otherNote = result.data.otherNote;
                    this.powerPointSelectionReason.reconnaissanceCustomerSignature =
                        result.data.reconnaissanceCustomerSignature;
                    this.powerPointSelectionReason.recommendOtherReason = result.data.recommendOtherReason;
                    this.powerPointSelectionReason.checkedOther =
                        this.powerPointSelectionReason.recommendOtherReason &&
                        this.powerPointSelectionReason.recommendOtherReason !== '';

                    // 勘查意见
                    this.surveyOpinion.reconnaissanceSCSignature = result.data.reconnaissanceSCSignature || {};
                    this.surveyOpinion.reconnaissanceFJSignature = result.data.reconnaissanceFJSignature || {};
                }
            });
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        },
    },
    created() {
        this.initFormValue();
        this.initData();
    },
};
</script>

<style lang="less" scoped>
</style>
